import axios from "axios";
import api from './api';

// 注册发送验证码
export const regSend = (params) => {
    return api.post(`/verification/regSend`,params)
};

//登录发送验证码
export const loginSend= (params) => {
    return api.post(`/verification/loginSend`,params)
};

//修改手机号发送验证码
export const userSend= (params) => {
    return api.post(`/verification/userSend`,params)
};

